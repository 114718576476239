import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { API_URL } from 'src/app/constants/url';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private _http: HttpService) { }

  getCoferenceEventData(conferenceId:string) {
    return lastValueFrom(this._http.get(API_URL.eventData, {conferenceId: conferenceId}))
  }

  getPlaceDetails(placeId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const service = new google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails({ placeId }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(place);
        } else {
          reject(`Error fetching place details: ${status}`);
        }
      });
    });
  }
}
