import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { HomeAction } from './home.actions';
import { HomeService } from 'src/app/home/services/home.service';
import { Router } from '@angular/router';

export interface HomeStateModel {
    conferenceData: any;
    isDataLoading: boolean;
    userDetails: any;
    showHeader: boolean;
}

@State<HomeStateModel>({
    name: 'Home',
    defaults: {
       conferenceData: {},
       isDataLoading: true,
       userDetails: {},
       showHeader: false
    }
})

@Injectable()
export class HomeState { 

    @Selector()
    static conferenceData(state: HomeStateModel) {
      return state.conferenceData
    }

    @Selector()
    static isDataLoading(state: HomeStateModel) {
        return state.isDataLoading;
    }

    @Selector()
    static showHeader(state: HomeStateModel) {
        return state.showHeader;
    }

    constructor(private _home: HomeService, private _router: Router) {}

    @Action(HomeAction.GetHomeEventData, {cancelUncompleted: true})
    async getEventData({getState, patchState}: StateContext<HomeStateModel>, action: HomeAction.GetHomeEventData) {
        try {
            patchState({isDataLoading: true})
            const state = getState()
        if(!state.conferenceData[action.conferenceId]) {
        let res = await this._home.getCoferenceEventData(action.conferenceId);
        if(res) {
            let conferenceData:any = {}; 
            conferenceData[action.conferenceId] = res
            patchState({
                conferenceData,
                isDataLoading: false
            })
        }
        } else {
            patchState({isDataLoading: false})
        }
        } catch(e) {
            patchState({isDataLoading: false})
            localStorage.removeItem('conference')
            this._router.navigate(['not-found'])
        }
    }

    @Action(HomeAction.ShowHeader)
    showHomeSkeleton({patchState}: StateContext<HomeStateModel>, action: HomeAction.ShowHeader) {
        patchState({
            showHeader: action.show
        })
    }


}